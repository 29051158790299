import logo from './logo.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Profile" />
        <h1>Laurent Shala</h1>
        <a href="https://github.com/laurentshala"><button class="button" type="button"><i class="fab fa-github"></i> <span>GitHub</span></button></a>
        <a href="https://linkedin.com/in/laurentshala"><button class="button" type="button"><i class="fab fa-linkedin"></i> <span>LinkedIn</span></button></a>
        <a href="https://twitter.com/laurentshala"><button class="button" type="button"><i class="fab fa-twitter"></i> <span>Twitter</span></button></a>
      </header>
    </div>
  );
}

export default App;
